import {
  booleanAttribute,
  ChangeDetectionStrategy,
  Component,
  contentChild,
  inject,
  input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { LoadingService } from "@/services/loading.service";
import { NgTemplateOutlet } from "@angular/common";
import {
  NavigationCancel,
  NavigationEnd,
  NavigationStart,
  Router,
} from "@angular/router";
import { Destroyable } from "@/classes/destroyable";
import { takeUntil } from "rxjs";

@Component({
  selector: "cup-loading-indicator",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    @use "cup";

    .spinner-container {
      position: fixed;
      height: cup.toRem(4);
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      background: transparent;
      z-index: 2000;
      border-bottom: cup.toRem(1) solid #000;

      &--loading {
        opacity: 1;
        background: rgba(0, 0, 0, 0.32);
      }

      &--complete {
        animation: fadeOut 500ms 500ms ease-in-out both 1;
      }

      .loading-bar {
        height: 100%;
        width: 30%;
        position: relative;
        border-top-right-radius: cup.toRem(8);
        border-bottom-right-radius: cup.toRem(8);
        background-color: #fff;
        box-shadow: 0 0 cup.toRem(6) 0 #fff;

        &--loading {
          animation: pulse linear infinite 2s;
        }

        &--complete {
          width: 100%;
          animation: none;
        }
      }

      @keyframes pulse {
        from {
          left: -30%;
        }

        to {
          left: 130%;
        }
      }

      @keyframes fadeOut {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }
    }
  `,
  template: `
    <div
      aria-hidden="true"
      class="spinner-container"
      [class.spinner-container--loading]="loadingService.isAppLoading()"
      [class.spinner-container--complete]="loadingService.isLoadingComplete()"
    >
      @let customTemplate = customLoadingIndicator();

      @if (customTemplate) {
        <ng-container *ngTemplateOutlet="customTemplate" />
      } @else {
        <div
          class="loading-bar"
          [class.loading-bar--loading]="loadingService.isAppLoading()"
          [class.loading-bar--complete]="loadingService.isLoadingComplete()"
        ></div>
      }
    </div>
  `,
  imports: [NgTemplateOutlet],
})
export class LoadingIndicatorComponent extends Destroyable implements OnInit {
  loadingService = inject(LoadingService);
  router = inject(Router);

  detectRouteTransitions = input(false, {
    transform: booleanAttribute,
  });

  customLoadingIndicator = contentChild<TemplateRef<void>>("loading");

  ngOnInit() {
    if (!this.detectRouteTransitions()) return;

    const key = Date.now();

    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        return this.loadingService.loadingOn(key);
      }

      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        return this.loadingService.loadingOff(key);
      }
    });
  }
}
