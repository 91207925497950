import { environment } from "@/environments/environment";
import { type AuthConfig } from "angular-oauth2-oidc";

export const authConfig: AuthConfig = {
  issuer: "https://identity.puntozeroscarl.it/oauth2/oidcdiscovery",
  responseType: "code",
  scope: "openid profile email offline_access api",
  redirectUri: environment.redirectUri,
  clientId: environment.clientID,
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: true,
  useSilentRefresh: true,
};
