import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import Link from "@/types/link.interface";

@Component({
  selector: "cup-links-footer-column",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: `
    @use "cup";

    .footer-links-list {
      display: flex;
      flex-direction: column;
      gap: cup.toRem(16);
    }
  `,
  template: `
    <ul class="footer-links-list text-body">
      @for (link of links(); track link.href) {
        <li>
          <a
            class="link link--underlined inverse"
            [href]="link.href"
            [target]="link.target"
            >{{ link.label }}</a
          >
        </li>
      }
    </ul>
  `,
})
export class LinksFooterColumnComponent {
  links = input.required<Link[]>();
}
