import { IconSize } from "@/types/icon-size.type";
import { Directive, input } from "@angular/core";
import toRem from "@/utils/functions/to-rem";

@Directive()
export class IconBase {
  readonly sizes = ["small", "normal", "large"];

  readonly sizeMap: Record<IconSize, number> = {
    small: 1,
    normal: 1.5,
    large: 2,
  };

  backgroundColor = input<string>("white");

  isCorrectSize(size: string | number): size is IconSize {
    if (typeof size === "number") return false;

    return this.sizes.includes(size);
  }

  get sizeMeasure() {
    const actualSize = this.size();

    if (this.isCorrectSize(actualSize)) {
      return this.sizeMap[actualSize];
    }

    return toRem(actualSize);
  }

  size = input<IconSize | number>("normal");
}
