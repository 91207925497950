import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { PhoneNumberPipe } from "@/pipes/phone-number.pipe";

@Component({
  selector: "cup-contacts-column",
  imports: [PhoneNumberPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <address class="text-body">
      <strong>{{ region() }}</strong
      ><br />
      {{ address() }}<br />
      {{ postalCode() }} {{ city() }}<br />
      <!--
      <br />
      <ng-container i18n="Centralino telefonico">Centralino</ng-container>
      <br />
      <a
        class="link inverse text-decoration-underline"
        [href]="'tel:' + phoneNumber()"
        >{{ phoneNumber() | phoneNumber }}</a
      >
      -->
    </address>
  `,
  standalone: true,
})
export class ContactsFooterColumnComponent {
  region = input.required<string>();
  postalCode = input.required<string>();
  city = input.required<string>();
  address = input.required<string>();
  phoneNumber = input.required<string>();
}
