import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
} from "@angular/common/http";
import { catchError, of, switchMap, throwError } from "rxjs";
import { APIError } from "@/types/api";
import { ResponseClientError } from "@/classes/errors";
import { ErrorHandler, inject } from "@angular/core";
import cancelRequest$ from "@/subjects/cancel-request";
import { fromPromise } from "rxjs/internal/observable/innerFrom";

const responseErrorHandlerInterceptor: HttpInterceptorFn = (req, next) => {
  const errorHandler = inject(ErrorHandler);

  const handleErrorMessage = (params: {
    err: HttpErrorResponse;
    apiError: APIError;
  }) => {
    const status = params.apiError?.status || params.err.status;
    const title = getErrorTitle(status);
    const message = getErrorMessage({
      status,
      responseErrorMessage: params.err.message,
      apiErrorMessage: params.apiError?.detail,
    });

    const error = new ResponseClientError({
      title,
      detail: message,
      status,
    });

    errorHandler.handleError(error);

    return error;
  };

  return next(req).pipe(
    catchError((err) => {
      if (err instanceof HttpErrorResponse) {
        const apiError = (
          typeof err.error === "string" ? JSON.parse(err.error) : err.error
        ) as APIError;

        if (err.error instanceof Blob) {
          return fromPromise(parseBlobText<APIError>(err.error)).pipe(
            switchMap((apiError) => {
              const error = handleErrorMessage({ err, apiError });
              return throwError(() => error);
            })
          );
        }

        // ignored 404 case (used in app when user contact data are not available)
        if (apiError?.status === 404) {
          return of(new HttpResponse({ status: 200, body: null }));
        }

        cancelRequest$.next();

        const error = handleErrorMessage({
          err,
          apiError,
        });

        return throwError(() => error);
      }

      return throwError(() => new Error($localize`Errore non previsto`));
    })
  );
};

export default responseErrorHandlerInterceptor;

const getErrorTitle = (status: number) => {
  return (
    {
      404: $localize`Oggetto non trovato`,
      500: $localize`Errore`,
    }[status] || $localize`Errore ${status}`
  );
};

const getErrorMessage = (params: {
  apiErrorMessage: string;
  responseErrorMessage: string;
  status: number;
}) => {
  if (params.apiErrorMessage === "CUP non attivo") {
    return $localize`Non è stato possibile procedere a causa di un errore relativo a questa richiesta.`;
  }

  return (
    params.apiErrorMessage ||
    {
      404: $localize`L'oggetto che stavi cercando non è stato trovato. È possibile che non sia più disponibile.`,
      500: $localize`L'operazione non è andata a buon fine. Riprova più tardi.`,
    }[params.status] ||
    params.responseErrorMessage
  );
};

const parseBlobText = <T>(blob: Blob): Promise<T> => {
  return new Promise((resolve) => {
    const reader: FileReader = new FileReader();

    reader.onloadend = () => {
      resolve(JSON.parse(reader.result as string));
    };

    reader.readAsText(blob);
  });
};
