import { inject, Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Pipe({
  name: "replaceBindings",
  standalone: true,
})
export default class ReplaceBindingsPipe implements PipeTransform {
  sanitizer = inject(DomSanitizer);

  transform(value?: string): SafeHtml {
    if (!value) {
      return "";
    }

    const phoneRegex = /#\$#(.*?)#\$#/g;
    const emailRegex = /###(.*?)###/g;

    let transformed = value.replace(
      phoneRegex,
      (_, phone) => `<a href="tel:${phone.trim()}">${phone.trim()}</a>`
    );

    transformed = transformed.replace(
      emailRegex,
      (_, email) => `<a href="mailto:${email.trim()}">${email.trim()}</a>`
    );

    return this.sanitizer.bypassSecurityTrustHtml(transformed);
  }
}
