import {
  ChangeDetectionStrategy,
  Component,
  inject,
  input,
} from "@angular/core";
import { ItDropdownComponent } from "design-angular-kit";
import { RouterLink } from "@angular/router";
import { absoluteRoutesPaths } from "@/classes/route-utils";
import { AvatarComponent } from "@/components/icons/avatar-icon.component";
import { UserService } from "@/services/user.service";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
  selector: "cup-header",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: `
    @use "cup";

    .header {
      z-index: 2;
      color: #fff;

      &__top {
        position: sticky;
        top: 0;
        @include cup.flex-center-between();
        padding: 0 cup.toRem(16);
        background-color: cup.$color-background-primary-dark;

        @include cup.media-breakpoint-up(lg) {
          padding: 0 cup.toRem(30);
        }
      }

      &__bottom {
        background-color: cup.$color-background-primary;
        padding: cup.toRem(16);
        text-decoration: none;

        @include cup.media-breakpoint-up(lg) {
          padding: cup.toRem(24) cup.toRem(30);
        }

        &-heading {
          font-size: cup.toRem(20);
          line-height: cup.toRem(24);
          font-weight: 600;
          color: cup.$color-text-inverse;

          @include cup.media-breakpoint-up(lg) {
            font-weight: 700;
            font-size: cup.toRem(32);
            line-height: cup.toRem(40);
          }
        }
      }

      &__logo {
        height: cup.toRem(80);
      }
    }

    .login-button {
      @extend .link;
      @include cup.flex-align-centered();

      color: #fff;
      padding: cup.toRem(8);
      gap: cup.toRem(8);
      text-decoration: none;

      @include cup.media-breakpoint-up(lg) {
        padding: cup.toRem(14) cup.toRem(24);
      }

      cup-avatar-icon {
        color: cup.$color-text-primary;
      }
    }
  `,
  template: `
    <header class="header">
      <div class="header__top">
        <div class="container-xxl header__top">
          <p class="small" i18n>
            <a
              class="link inverse"
              href="https://www.regione.umbria.it/"
              target="_blank"
              >Regione Umbria</a
            >
          </p>
          <div class="d-flex gap-3 align-items-center">
            @if (user.isLogged()) {
              <it-dropdown>
                <span button>{{ user.userJwtFullName }}</span>
                <ng-container list>
                  <li>
                    <a [routerLink]="absoluteRoutesPaths.userProfile" i18n
                      >Profilo e contatti</a
                    >
                  </li>
                  <li>
                    <a
                      routerLink="/"
                      class="dropdown-list-item-btn"
                      (click)="handleLogout()"
                      i18n
                    >
                      Esci
                    </a>
                  </li>
                </ng-container>
              </it-dropdown>
            } @else {
              <button
                type="button"
                (click)="oauthService.initLoginFlow()"
                class="login-button bg-transparent border-0"
              >
                <cup-avatar-icon class="d-lg-none" [size]="40" />
                <cup-avatar-icon class="d-none d-lg-block" />
                <span class="d-none d-lg-inline" i18n
                  >Accedi all'area personale</span
                >
              </button>
            }
          </div>
        </div>
      </div>
      <div class="header__bottom">
        <div class="container-xxl header__bottom">
          <a
            routerLink="/"
            class="d-inline-flex gap-2 align-items-center text-decoration-none"
          >
            <img
              class="header__logo"
              src="/assets/cup-logo-orizzontale-01.svg"
              i18n
              alt="CUP Umbria Centro Unico Prenotazioni"
              title="CUP Umbria Centro Unico Prenotazioni"
            />
          </a>
        </div>
      </div>
    </header>
  `,
  imports: [RouterLink, AvatarComponent, ItDropdownComponent],
})
export class HeaderComponent {
  headerTitle = input.required<string>();
  slimTitle = input.required<string>();
  user = inject(UserService);
  oauthService = inject(OAuthService);

  handleLogout() {
    this.user.logout();
  }

  protected readonly absoluteRoutesPaths = absoluteRoutesPaths;
}
