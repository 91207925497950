import { HttpContextToken, HttpInterceptorFn } from "@angular/common/http";
import { finalize, takeUntil } from "rxjs";
import { ErrorHandler, inject } from "@angular/core";
import { LoadingService } from "@/services/loading.service";
import { ItNotificationService } from "design-angular-kit";
import { $localize } from "@angular/localize/init";
import cancelRequest$ from "@/subjects/cancel-request";
import { ResponseClientError } from "@/classes/errors";

export const HideSpinner = new HttpContextToken<boolean>(() => false);

const LOADING_WARNING_TIMEOUT = 30000;
const EXPIRE_TIMEOUT = 60000;

const loadingSpinnerInterceptor: HttpInterceptorFn = (req, next) => {
  const loadingService = inject(LoadingService);
  const notificationService = inject(ItNotificationService);
  const errorHandler = inject(ErrorHandler);

  const date = Date.now();

  if (!req.context.get(HideSpinner)) {
    loadingService.loadingOn(date);
  }

  const timeoutId = window.setTimeout(() => {
    notificationService.standard(
      $localize`Risorsa in fase di caricamento`,
      $localize`Il server sta impiegando molto tempo a rispondere. Ti preghiamo di attendere.`
    );
  }, LOADING_WARNING_TIMEOUT);

  const expireTimeoutId = window.setTimeout(() => {
    errorHandler.handleError(
      new ResponseClientError({
        title: $localize`Timeout richiesta`,
        detail: $localize`Il server ha impiegato troppo tempo a rispondere. La richiesta è stata abortita. Riprova più tardi.`,
      })
    );

    cancelRequest$.next();
  }, EXPIRE_TIMEOUT);

  return next(req).pipe(
    takeUntil(cancelRequest$),
    finalize(() => {
      clearTimeout(timeoutId);
      clearTimeout(expireTimeoutId);
      loadingService.loadingOff(date);
    })
  );
};

export default loadingSpinnerInterceptor;
