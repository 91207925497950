import { Routes } from "@angular/router";

import {
  homepageRoute,
  newReservationConfirmedRoute,
  newReservationProviderRoute,
  otherReservationsDetailRoute,
  otherReservationsRoute,
  reservationDetailRoute,
  reservationHistoryDetailRoute,
  reservationHistoryRoute,
  userProfileContactRoute,
  userProfileContactSPIDRoute,
  userProfileRoute,
  userReservationsRoute,
  accessibilityRoute,
} from "@/routes/index";

export const routes: Routes = [
  homepageRoute,
  userProfileRoute,
  userProfileContactRoute,
  userProfileContactSPIDRoute,
  otherReservationsRoute,
  otherReservationsDetailRoute,
  userReservationsRoute,
  newReservationProviderRoute,
  newReservationConfirmedRoute,
  reservationHistoryRoute,
  reservationDetailRoute,
  reservationHistoryDetailRoute,
  accessibilityRoute,
  {
    path: "**",
    redirectTo: "",
  },
];
