import { inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { takeUntil } from "rxjs";
import cancelRequest$ from "@/subjects/cancel-request";

@Injectable({
  providedIn: "root",
})
export class CupService {
  httpClient = inject(HttpClient);

  getCupContext() {
    return this.httpClient.get("cup-context").pipe(takeUntil(cancelRequest$));
  }

  getCupSession() {
    return this.httpClient
      .get<{ idSessione: number }>("cupservice/cup-session")
      .pipe(takeUntil(cancelRequest$));
  }
}
