import { ChangeDetectionStrategy, Component, inject } from "@angular/core";
import { Router, RouterLink } from "@angular/router";
import { ItButtonDirective } from "design-angular-kit";

@Component({
  selector: "cup-skip-link",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    @use "cup";

    .jump-btn {
      position: absolute;
      overflow: hidden;
      z-index: 999;
      height: cup.toRem(1);
      width: cup.toRem(1);
      left: cup.toRem(-10000);

      &:focus-visible {
        width: auto;
        height: auto;
        top: cup.toRem(10);
        left: cup.toRem(10);
      }
    }
  `,
  template: `
    <a
      class="jump-btn"
      [routerLink]="routerLink"
      fragment="content-start"
      tabindex="0"
      itButton="primary"
      i18n
    >
      Salta al contenuto principale
    </a>
  `,
  imports: [ItButtonDirective, RouterLink],
})
export class SkipLinkComponent {
  router = inject(Router);

  get routerLink() {
    return this.router.url.split("#")[0].split("?")[0];
  }
}
