import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IconBase } from "@/classes/icon-base.class";

@Component({
  selector: "cup-avatar-icon",
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    :host {
      font-size: 0;
    }
  `,
  template: `
    <svg
      [style.font-size.rem]="sizeMeasure"
      width="24"
      height="24"
      aria-hidden="true"
      viewBox="0 0 24 24"
      class="custom-icon"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_891_36388)">
        <g clip-path="url(#clip1_891_36388)">
          <circle cx="12" cy="12" r="12" [attr.fill]="backgroundColor()" />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 12.5C13.1046 12.5 14 11.6046 14 10.5V9C14 7.89543 13.1046 7 12 7C10.8954 7 10 7.89543 10 9V10.5C10 11.6046 10.8954 12.5 12 12.5ZM10.5 9C10.5 8.17157 11.1716 7.5 12 7.5C12.8284 7.5 13.5 8.17157 13.5 9V10.5C13.5 11.3284 12.8284 12 12 12C11.1716 12 10.5 11.3284 10.5 10.5V9ZM15.5 17H16C16 14.7909 14.2091 13 12 13C9.79086 13 8 14.7909 8 17H8.5C8.5 15.067 10.067 13.5 12 13.5C13.933 13.5 15.5 15.067 15.5 17Z"
            fill="currentColor"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_891_36388">
          <rect width="24" height="24" rx="12" fill="white" />
        </clipPath>
        <clipPath id="clip1_891_36388">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  `,
})
export class AvatarComponent extends IconBase {}
