import {
  ApplicationConfig,
  ErrorHandler,
  inject,
  LOCALE_ID,
  provideAppInitializer,
  provideExperimentalZonelessChangeDetection,
} from "@angular/core";
import { provideDesignAngularKit } from "design-angular-kit";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  HttpRequest,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import {
  provideRouter,
  withInMemoryScrolling,
  withViewTransitions,
} from "@angular/router";
import { routes } from "./app.routes";
import { tap } from "rxjs";
import { MockService } from "@/services/mock.service";
import { provideOAuthClient } from "angular-oauth2-oidc";
import {
  provideNgHttpCaching,
  withNgHttpCachingSessionStorage,
} from "ng-http-caching";
import urlAuthorizationInterceptor from "../core/interceptors/url-authorization.interceptor";
import { environment } from "@/environments/environment";
import hash from "object-hash";
import loadingSpinnerInterceptor from "../core/interceptors/loading-spinner.interceptor";
import responseErrorHandlerInterceptor from "../core/interceptors/response-error-handler.interceptor";
import { registerLocaleData } from "@angular/common";

import localeIt from "@angular/common/locales/it";
import { GlobalErrorHandler } from "@/app/app.component";

registerLocaleData(localeIt);

const hashOptions = {
  algorithm: "md5",
  encoding: "hex",
} as const;

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: LOCALE_ID, useValue: "it-IT" },
    provideRouter(
      routes,
      withViewTransitions(),
      withInMemoryScrolling({
        scrollPositionRestoration: "enabled",
        anchorScrolling: "enabled",
      })
    ),
    provideAnimations(),
    provideNgHttpCaching({
      store: withNgHttpCachingSessionStorage(),
      allowedMethod: ["GET", "POST", "PUT", "DELETE", "HEAD"],
      getKey: (req: HttpRequest<unknown>): string | undefined => {
        // In this example the full request is hashed for provide an unique key for the cache.
        // This is important if you want support method like POST or PUT.
        let key = `${req.method}@${req.urlWithParams}`;

        const queryParams = req.params.toString();
        if (queryParams) {
          key += `${hash(queryParams, hashOptions)}`;
        }

        if (req.body) {
          key += `@${hash(req.body, hashOptions)}`;
        }

        return key;
      },
    }),
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([
        urlAuthorizationInterceptor,
        loadingSpinnerInterceptor,
        responseErrorHandlerInterceptor,
      ])
    ),
    provideOAuthClient(),
    provideDesignAngularKit(),
    provideExperimentalZonelessChangeDetection(),
    provideAppInitializer(() => {
      if (!environment.RUN_MOCK_APP_INITIALIZER) return;

      const mock = inject(MockService);

      return mock.getMockData().pipe(
        tap((response) => {
          if (response.status === "success") {
            mock.data.set(response.data);
            return;
          }

          mock.error.set(response);
        })
      );
    }),
  ],
};
