import { ChangeDetectionStrategy, Component, input } from "@angular/core";
import { ItIconComponent } from "design-angular-kit";
import Link from "@/types/link.interface";

@Component({
  selector: "cup-socials-footer-column",
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: `
    @use "cup";

    .footer-socials-list {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      gap: cup.toRem(24);
      padding: 0;
      margin: 0;
      color: cup.$color-text-inverse;

      svg {
        filter: invert(1);
        width: cup.toRem(24);
        height: cup.toRem(24);
      }
    }
  `,
  template: `
    <ul class="footer-socials-list">
      @for (social of socials(); track social.icon) {
        <li>
          <a
            title="Il link alla pagina {{
              social.label
            }} verrà aperto in una nuova scheda"
            i18n-title
            [attr.aria-label]="social.label"
            [href]="social.href"
            [target]="social.target"
          >
            @if (social.icon) {
              <it-icon color="white" [name]="social.icon" />
            }
          </a>
        </li>
      }
    </ul>
  `,
  imports: [ItIconComponent],
  standalone: true,
})
export class SocialsFooterColumnComponent {
  socials = input.required<Link[]>();
}
