import { HttpInterceptorFn } from "@angular/common/http";
import { environment } from "@/environments/environment";
import { inject } from "@angular/core";
import { SessionStorageService } from "@/services/session-storage.service";

const urlAuthorizationInterceptor: HttpInterceptorFn = function (req, next) {
  if (req.url.includes("json") || req.url.includes("oauth2")) {
    return next(req);
  }

  const sessionStorageService = inject(SessionStorageService);

  const authToken = sessionStorageService.accessToken;

  return next(
    req.clone({
      ...req,
      url: `${environment.apiUrl}/${req.url}`,
      ...(authToken
        ? {
            headers: req.headers.append("Authorization", `Bearer ${authToken}`),
          }
        : {}),
    })
  );
};

export default urlAuthorizationInterceptor;
