import {
  ChangeDetectionStrategy,
  Component,
  ErrorHandler,
  inject,
  OnInit,
  signal,
  viewChild,
} from "@angular/core";
import { NavigationEnd, Router, RouterModule } from "@angular/router";
import { HeaderComponent } from "@/organisms/header.component";
import { ContactsBlockComponent } from "@/organisms/contacts-block/contacts-block.component";
import { FooterComponent } from "@/organisms/footer.component";
import { MockService } from "@/services/mock.service";
import FooterColumn from "@/types/footer-column.interface";
import { PrivacyFooterColumnComponent } from "@/atoms/contacts-table/privacy-footer-column.component";
import { ContactsFooterColumnComponent } from "@/atoms/contacts-table/contacts-footer-column.component";
import { SocialsFooterColumnComponent } from "@/atoms/contacts-table/socials-footer-column.component";
import { LinksFooterColumnComponent } from "@/atoms/contacts-table/links-footer-column.component";
import { map, skip, switchMap, takeUntil } from "rxjs";
import { filter } from "rxjs/operators";
import { Destroyable } from "@/classes/destroyable";
import FooterData from "@/types/footer-data.interface";
import { CapitalizePipe } from "@/pipes/capitalize.pipe";
import { toObservable, toSignal } from "@angular/core/rxjs-interop";
import {
  ItButtonDirective,
  ItModalComponent,
  ItNotificationsComponent,
  ItNotificationService,
} from "design-angular-kit";
import { OAuthService, OAuthSuccessEvent } from "angular-oauth2-oidc";
import { authConfig } from "@/app/auth.config";
import { CupService } from "@/services/cup.service";
import { UserService } from "@/services/user.service";
import { LoadingIndicatorComponent } from "@/atoms/loading-indicator/loading-indicator.component";
import { DatePipe } from "@angular/common";
import { MockData } from "@/types/mock-data.interface";
import { AppInfoService } from "@/services/app-info.service";
import { SessionStorageService } from "@/services/session-storage.service";
import { ResponseClientError } from "@/classes/errors";
import { ErrorService } from "@/services/error.service";
import ReplaceBindingsPipe from "@/pipes/replace-bindings.pipe";
import { environment } from "@/environments/environment";
import { SkipLinkComponent } from "@/atoms/skip-link/skip-link.component";
import cancelRequest$ from "@/subjects/cancel-request";

export class GlobalErrorHandler implements ErrorHandler {
  errorService = inject(ErrorService);

  handleError(error: unknown): void {
    if (error instanceof ResponseClientError) {
      this.errorService.responseError.set({
        title: error.title,
        detail: error.detail,
      });
    }
  }
}

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterModule,
    HeaderComponent,
    ContactsBlockComponent,
    FooterComponent,
    ItNotificationsComponent,
    LoadingIndicatorComponent,
    ItModalComponent,
    ItButtonDirective,
    ReplaceBindingsPipe,
    SkipLinkComponent,
  ],
  providers: [CapitalizePipe, DatePipe],
  selector: "cup-root",
  styles: `
    @use "cup";

    .outlet-wrapper {
      padding-top: cup.toRem(32);
    }
  `,
  template: `
    <cup-skip-link />

    <cup-header slimTitle="Regione Umbria" headerTitle="Cup Umbria" />

    <div class="outlet-wrapper">
      <router-outlet />
    </div>

    <it-notifications />

    <cup-loading-indicator detectRouteTransitions="true" />

    @defer (when routerIsReady()) {
      <cup-contacts-block />

      @let data = footerData();
      @if (data) {
        <cup-footer
          [title]="data.title"
          [caption]="data.caption"
          [links]="data.bottomLinks"
          [columns]="footerColumns()"
        />
      }
    }

    <it-modal alignment="centered" #responseErrorModal>
      @let modalData = errorService.responseError();

      <ng-container modalTitle i18n>
        {{ modalData?.title }}
      </ng-container>

      <p
        class="break-word"
        [innerHTML]="modalData?.detail | replaceBindings"
      ></p>
      <ng-container footer>
        <button (click)="responseErrorModal.hide()" itButton="primary">
          Ok
        </button>
      </ng-container>
    </it-modal>
  `,
  standalone: true,
})
export class AppComponent extends Destroyable implements OnInit {
  router = inject(Router);
  mockService = inject(MockService);
  errorService = inject(ErrorService);
  cupService = inject(CupService);
  sessionStorageService = inject(SessionStorageService);
  userService = inject(UserService);
  notificationService = inject(ItNotificationService);
  appInfoService = inject(AppInfoService);
  responseErrorModal =
    viewChild.required<ItModalComponent>("responseErrorModal");

  footerData = signal<FooterData>({} as FooterData);
  footerColumns = signal<FooterColumn[]>([]);
  routerIsReady = toSignal(
    this.router.events.pipe(map((e) => e instanceof NavigationEnd))
  );

  constructor(private oauthService: OAuthService) {
    super();

    this.oauthService.configure(authConfig);

    toObservable(this.errorService.responseError)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.responseErrorModal().show());

    void this.oauthService.loadDiscoveryDocumentAndTryLogin();

    if (this.sessionStorageService.sessionId) {
      this.userService.sessionId.set(+this.sessionStorageService.sessionId);
    }

    this.oauthService.events
      .pipe(
        filter(
          (event) =>
            event instanceof OAuthSuccessEvent &&
            event.type === "token_received"
        ),
        switchMap(() => this.cupService.getCupSession()),
        takeUntil(this.destroy$)
      )
      .subscribe(({ idSessione: sessionId }) => {
        this.sessionStorageService.updateSessionId(sessionId);
        this.userService.sessionId.set(sessionId);
      });
  }

  ngOnInit() {
    this.initFooterData();

    let refreshTimeoutId: number | null = null;

    if (this.userService.isUserLogged() && this.userService.isTokenExpired()) {
      return this.userService.clearSessionAndReload();
    }

    this.router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        skip(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        cancelRequest$.next();
      });

    window.addEventListener("focus", () => {
      if (
        this.userService.isUserLogged() &&
        this.userService.isTokenExpired()
      ) {
        return this.userService.clearSessionAndReload();
      }

      if (this.userService.isUserLogged()) {
        const timeRemaining = this.userService.getTokenTimeRemaining();
        const FIVE_MINUTES = 1_000 * 60 * 5;

        if (
          this.appInfoService.isUserSessionAlertShown() ||
          timeRemaining > FIVE_MINUTES
        ) {
          return;
        }

        if (refreshTimeoutId) {
          clearInterval(refreshTimeoutId);
        }

        refreshTimeoutId = window.setInterval(() => {
          const timeRemaining = this.userService.getTokenTimeRemaining();

          if (timeRemaining <= 0) {
            this.userService.clearSessionAndReload();
          }
        }, 1000);

        this.appInfoService.isUserSessionAlertShown.set(true);

        this.notificationService.warning(
          $localize`Sessione in scadenza`,
          $localize`Questa sessione scadrà a breve. Alla scadenza della sessione
          dovrai effettuare nuovamente l'accesso per proseguire con la navigazione.`
        );
      }
    });
  }

  initFooterData() {
    const data =
      this.mockService.data() ||
      ({
        footer: {
          title: "Regione Umbria",
          caption: "Giunta Regionale",
          privacyText:
            "I dati personali pubblicati sono riutilizzabili solo ai sensi dell'articolo 7 del decreto legislativo 33/2013",
          region: "Regione Umbria",
          address: "Corso Vannucci, 96",
          postalCode: "06121",
          city: "Perugia",
          phoneNumber: "",
          socials: [
            {
              href: "https://www.facebook.com/Regione.Umbria.official/",
              label: "Facebook",
              icon: "facebook",
              target: "_blank",
            },
            {
              href: "https://twitter.com/RegioneUmbria/",
              label: "X",
              icon: "twitter",
              target: "_blank",
            },
            {
              href: "https://github.com/Regione-Umbria/",
              label: "GitHub",
              icon: "github",
              target: "_blank",
            },
          ],
          links: [
            {
              href: "http://accessounico.regione.umbria.it/",
              label: "Accesso unico regionale ai servizi pubblici",
              target: "_blank",
            },
          ],
          bottomLinks: [
            {
              href: "/assets/InformativaPrivacy.pdf",
              label: "Informativa Privacy",
              target: "_blank",
            },
            {
              href: "/assets/InformativaPrivacySmartCup.pdf",
              label: "Privacy Smartcup",
              target: "_blank",
            },
            {
              href: "/accessibilita",
              label: "Accessibilità",
              target: "_self",
            },
            {
              href: "https://form.agid.gov.it/view/589f9110-056a-11f0-b364-8f72f43da62b",
              label: "Dichiarazione di accessibilità",
              target: "_blank",
            },
          ],
        },
      } as MockData);

    if (!data) return;

    const { footer } = data;

    this.footerData.set(footer);

    this.footerColumns.set([
      /*
      {
        sectionTitle: $localize`Amministrazione Trasparente`,
        component: PrivacyFooterColumnComponent,
        inputs: {
          privacyText: footer.privacyText,
        },
      },
      */
      {
        sectionTitle: $localize`Contatti`,
        component: ContactsFooterColumnComponent,
        inputs: {
          address: footer.address,
          region: footer.region,
          postalCode: footer.postalCode,
          city: footer.city,
          phoneNumber: footer.phoneNumber,
        },
      },
      {
        sectionTitle: $localize`Seguici su`,
        component: SocialsFooterColumnComponent,
        inputs: {
          socials: footer.socials,
        },
      },
      {
        component: LinksFooterColumnComponent,
        inputs: {
          links: footer.links,
        },
      },
    ]);
  }

  protected readonly environment = environment;
}
