import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalize",
  standalone: true,
})
export class CapitalizePipe implements PipeTransform {
  transform(text?: string): string {
    if (!text) {
      throw new Error("The text should be defined.");
    }

    return `${text.at(0)?.toUpperCase()}${text.slice(1)}`;
  }
}
